.banner{
	position: relative;
	.btn-scroll{
		position: absolute;
		bottom: 20px;
		text-align: center;
		left: 0;
		right: 0;
		z-index: 5;
		&:hover{
			cursor: pointer;
		}
	}
	&__caption{
		color: $white;
		font-style: italic;
		z-index: 5;
		.title{
			font-family: $font-family--secondary;
			text-transform: uppercase;
			font-size: 40px;
			@media (min-width: $medium-width){
				font-size: 60px;
			}
		}
		.subtitle{
			font-family: $font-family--primary;
			font-size: 24px;
			font-weight: normal;
		}
		.phone{
			font-family: $font-family--primary;
			font-size: 36px;
			font-weight: bold;
			letter-spacing: 2px;
			@media (min-width: $small-width){
				background-image: url('/dist/images/background-phone.png');
				background-position: 0% 50%;
			    background-repeat: no-repeat;
			    padding: 30px 10px;
			    background-size: 35% 50%;
			}
			
		
			.fa{
				color: $white;
				@media (min-width: $small-width){
					color: $color-primary;
				}
			}
		}
	}
	&--home{
		background-image: url('/dist/images/background-banner.png');
		height: 380px; 
	}
	&--layer{
		
		background-repeat: no-repeat;
		position: absolute;
		&-1{
			background-image: url('/dist/images/background-wave.png');
			opacity: .33;
			z-index: 1;
			background-size: cover;
			background-position: 50% 80px;
			height: 100%;
			width: 100%;
		}
		&-2{
			background-image: url('/dist/images/banner-hotspot.png');
			z-index: 2;
			background-position: 50%;
			height: 100%;
			width: 100%;
		}
		&-3:before {
			content: url('/dist/images/helice-bateau.png');
		}
		&-3 {
			z-index: 3;
			right: 0;
		}
	}
}