.partial{
	&--intro{
		.content{
			padding: 25px 0px; 
			@media (min-width: $medium-width){
				padding: 55px 170px; 
			}
		}
		p{
			color: $black;
			font-family: $font-family--ternary; 
			line-height: 30px;
			font-size: 14px;

		}
		.title-bar--primary{
			padding: 25px;
			background-color: $color-primary;
			color: $white;
			font-size: 36px;
			font-weight: bold;
			text-align: center;
		}

	}

}

