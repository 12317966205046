button, input[type=submit]{
	background-color: $color-secondary;
	color: $white;
	font-size: 20px;
	border-radius: 5px;
	padding: 10px;
	@extend %transition-base;
	margin: 20px auto 20px;
	display: block;
	@media (min-width: $small-width){
		margin: 70px auto 20px;
	}
	&:hover{
		background-color: $color-primary;
		@extend %transition-base;
		cursor: pointer;
	}
}