.template{
	&--realisations, &--contact{
		.content{
			padding: 55px 0; 
		}
		li{
			img{
				width: 100%;
				margin-bottom: 20px;
			}
		}
		.msg-success{
			color: green;
			background-color: rgba(0, 128, 0, 0.4);
			max-width: 200px;
    		margin: auto;
			border-color: green;
			border-radius: 2px;
			padding: 20px; 
		}
		p{
			color: $black;
			margin-bottom: 30px;
			@media (min-width: $medium-width){
				padding: 0 170px;
			}
		}
		.title{
			color: $color-primary;
		}
	}
	&--services{
	background-color: $color-primary;
	position: relative;
		a{
			font-family: $font-family--ternary;
			font-size: 14px;
			color: $color-secondary;
			font-weight: bold;
		}
		.background-image{
			background-image: url('/dist/images/background-wave.png');
			opacity: .13;
			background-size: contain;
			background-position: 50% 0px;
			background-repeat: no-repeat; 
			width: 100%;
			z-index: 1;
			position: absolute;
			height: 100%;
			-moz-transform: scaleX(-1);
		    -o-transform: scaleX(-1);
		    -webkit-transform: scaleX(-1);
		    transform: scaleX(-1);
		    filter: FlipH;
		    -ms-filter: "FlipH";
		}
		.banner{
			height: 200px;
			background-repeat: no-repeat;
			background-size: cover; 
			background-image: url('/dist/images/bateau-performance-vitesse.jpg');
			background-position: center center; 
		}
		.content{
			z-index: 2;
			position: relative;
			padding: 55px 0;
			@media (min-width: $medium-width){
				padding: 55px 170px;
			}
		}
		.title{
			color: $white;
		}
		.tooltip{
			position: relative;
    		display: block; 
    		color: $color-secondary;
    		margin-top: 15px;
    		&:before{
    			float: left;
    			margin-right: 5px;
    		}
			&:hover + .tooltiptext {
		         visibility: visible;
		    }
		    a:hover{
		    	cursor: pointer; 
		    }
		  
		}
		.tooltiptext{
			visibility: hidden;
		    min-width: 300px;
		    background-color: $color-secondary;
		    color: $white;
		    text-align: left;
		    border-radius: 2px;
		    padding: 15px;
		    position: absolute;
		    z-index: 1;
		   
		    h3{
		    	font-family: $font-family--primary; 
		    	color: $color-primary;
		    	margin-bottom: 10px;
		    }
		}
		ul{
			color: $color-secondary;
			margin-top: 10px;
			li{
				margin-bottom: 10px;
				&:before{
					margin-right: 10px;
					float: left;
				}
				&:hover{
					cursor: pointer;
				}
			}
			
		}
	
	}
	h2{
		color: $color-secondary; 
		margin-top: 20px;
	}
	p{
		font-family: $font-family--ternary;
		font-size: 14px;
	}
	.title{
		padding-bottom: 25px;
		font-size: 36px;
		font-weight: bold;
		text-align: center;
	}

	&--videos{
		padding: 55px 0;
		background-color: $color-secondary;
		position: relative;
		overflow: hidden;
		.background-image{
			background-image: url('/dist/images/background-wave.png');
			opacity: .13;
			background-size: cover;
			background-position: 50% 0px;
			background-repeat: no-repeat;
			width: 100%;
			z-index: 1;
			position: absolute;
			height: 100%;
		}
		.title{
			color: $white;
			padding-bottom: 0;
		}
	}
}

