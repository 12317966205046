form{
	text-align: center;
	margin-top: 40px;
	input, textarea{
		border: $gray-light 1px solid;
		padding: 10px;
		margin-top: 20px;
		width: 200px;
	}
	.hp-field{
		display: none;
	}
	button[type=submit]{
		margin-top: 40px;
	}
 	.form-error{
		display: block;
		color: rgb(185, 74, 72);
	}
	
}