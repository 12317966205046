header{
	min-height: 115px;
	border-bottom: solid $color-primary 10px;
	.lang__container{
		font-weight: bold;
		border-radius: 50%;
		background-color: $color-primary;
		width: 35px;
	    height: 35px;
	    color: #fff;
	    text-align: center;
	    padding-top: 3px;
	    margin-right: 10px;
	    position: absolute;
	    top: 5px;
	    right: 0;
	    text-transform: lowercase;
	    font-size: 24px;
	    z-index: 2;
	    @extend %transition-base; 
	    display: none;
	     @media only screen and (min-width: $small-width) {
	     	display: block;
	     }
	    &:hover{
	    	background-color: $color-secondary;
	    	transform: scale(1.1);
	    	@extend %transition-base; 
	    }
	    a{
	    	text-decoration: none;
	    }
	}
	.logo__container{
		height: 60px;
		margin-top: 15px;
		@media only screen and (min-width: $small-width) {
			margin-bottom: 15px;
		}
		@media only screen and (min-width: $medium-width) {
			margin-bottom: 0px;
		}
		img{
			height: 100%;
		}
	}
	.nav__container{
		background-color: $color-secondary;
		position: absolute;
		top: 115px;
		width: 100%;
		opacity: 0;
		z-index: 10;
		@extend %transition-base;
		@media only screen and (min-width: $small-width) {
			background-color: transparent;
			position: relative;
			top: inherit;
			opacity: 1;
		}
		.header-navigation{
			&--primary{
				text-align:center;
				font-weight: bold;
				text-transform: uppercase;
				font-family: $font-family--secondary;
				font-style: italic; 
				margin-bottom: 10px;
				li{
					display: block;
					padding: 10px 20px;
					color: $white;
					@extend %transition-base;
					@media only screen and (min-width: $small-width) {
						display: inline-block;
						padding: 0px 20px;
						color: $color-secondary;
					}

					&:hover{
						color: $white;
						@media only screen and (min-width: $small-width) {
						color: $color-primary;
						}
						@extend %transition-base; 
					}
					&:not(:last-of-type){
						border-bottom: 1px solid $white;
						@media only screen and (min-width: $small-width) {
							border: 0;
						}
					}
					a{
						text-decoration: none;
					}
					&.menu-item__lang{
						@media only screen and (min-width: $small-width) {
							display: none;
						}
					}
				}

			}
		}
		&.is-open{
			opacity: 1;
		}
	}
}