footer{
	
	.address{
		font-family: $font-family--ternary; 
		color: white;
		font-size: 14px;
		   z-index: 2;
    	position: relative;
		@media (min-width: $small-width){
			margin-left: 70px;
		}
	}
	.container{
		text-align: center;
		@media (min-width: $small-width){
			text-align: inherit;
		}

	}
	.info__container{
		position: relative;
		.background-image{
			background-image: url('/dist/images/background-wave.png');
			opacity: .13;
			background-size: cover;
			background-position: 50% 0px;
			background-repeat: no-repeat;
			width: 100%;
			z-index: 1; 
			position: absolute;
			height: 100%;
			-moz-transform: scaleX(-1);
		    -o-transform: scaleX(-1);
		    -webkit-transform: scaleX(-1);
		    transform: scaleX(-1);
		    filter: FlipH;
		    -ms-filter: "FlipH";
		}
	}
	.intro-text{
		font-family: $font-family--secondary;
		font-size: 13px;
	}

	.logo__container{
		margin-top: 20px;
		img{
			width: 200px;
			margin-bottom: 20px;
		}

	}
	.container-fluid{
		
		ul{
			text-align: center;
			li{
				display: block;
				color: $white;
				margin: 10px 25px;
				font-size: 14px;
				text-transform: uppercase;
				font-family: $font-family--ternary;
				@media (min-width: $small-width){
					display: inline-block;
					margin: 0px 25px;
				}

			}
		}
	}
	.copyright{
		color: $white;
		font-family: $font-family--ternary;
		font-size: 12px;
		margin-bottom: 20px;
	}
	.info__container{
		background-image: url(/dist/images/background-footer.png);
	}
	.menu__container{
		background-color: $color-secondary;
		padding: 10px 0;
	}
	.partners{
		margin: 20px 0;
		z-index: 2;
		position: relative;
		@media (min-width: $small-width){
			text-align: right;
			margin: 0;
		}
	}
	.social__container{
		color: $white;
		margin-top: 20px;
		z-index: 2;
		@media (min-width: $small-width){
			text-align: right;
		}
		.fa{
			padding: 0 5px;
			font-size: 40px;
			@extend %transition-base; 
			&:hover{
				cursor: pointer;
				transform: scale(1.1);
	    		@extend %transition-base; 
			}
		}
	}
}