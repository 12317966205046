.slick-lightbox {
	button{
		background-color: transparent;
		margin-top: 0;
		&:hover{
			background-color: transparent;
		}
	}
}


.slick-lightbox--realisation {
	list-style: none;
}